///////////////// GLOBAL SCOPE //////////////////
import * as Focus from '../../../assets/scripts/common/focus';
require('./mods/stylesheet-control.js');
require('./mods/font-size-control.js');
// eslint-disable-next-line no-undef
const vtx_accessibility = VTX_ACCESSIBILITY || {};
/**
 * Ajouter une méthode à l'object vtx_accessibility créé avec wp_localize_script (voir inc/accessibility.init.php)
 */
export function extend_vtx_accessibility() {
  vtx_accessibility.toggle_related_input_state =
    function toggle_related_input_state($input) {
      var accessibility_type = $input.data('accessibility-type');
      var accessibility_type_value = $input.data(accessibility_type);
      var $related_el = $(
        '[data-' + accessibility_type + "='" + accessibility_type_value + "']"
      ).not('#' + $input.attr('id'));

      if ($related_el.is("[type='checkbox']")) {
        // Toggle checked state
        var is_checked = !!$related_el.prop('checked');
        $related_el.prop('checked', !is_checked);
      } else if ($related_el.is("[type='radio']")) {
        $related_el.prop('checked', true);
      }
    };
}

/**
 *
 * @param {jQuery object} $toggle
 * @param {string} state May be "closed" or "opened"
 */
export function change_toggle_state($toggle, state) {
  var data_label_name = '';

  // CLOSED
  if ('closed' === state) {
    data_label_name = 'label-open';
    $toggle.removeClass('opened');
    $toggle.attr('aria-expanded', 'false');
  }
  // OPENED
  else if ('opened' === state) {
    data_label_name = 'label-close';
    $toggle.addClass('opened');
    $toggle.attr('aria-expanded', 'true');
  }

  var label = $toggle.data(data_label_name);
  if (label) {
    $toggle.find('.accessibility-menu__toggle__label').html(label);
  }
}

export function close_accessibility_menu(e, give_focus_back_to_toggle) {
  /* jshint ignore:start */
  $(document)
    .off('click', try_closing_accessibility_menu_on_click)
    .off('keydown', try_closing_accessibility_menu_on_keydown);
  /* jshint ignore:end */

  if (give_focus_back_to_toggle) {
    $('.accessibility-menu__toggle.opened').focus();
  }

  var $menu = $('.accessibility-menu.opened');

  Focus.untrap_focus_inside($menu);

  change_toggle_state($('.accessibility-menu__toggle.opened'), 'closed');

  $menu.removeClass('opened');
}

export function try_closing_accessibility_menu_on_keydown(e) {
  if (27 === e.keyCode) {
    // When ESCAPE is pressed
    close_accessibility_menu(e, true);
  }
}

export function try_closing_accessibility_menu_on_click(e) {
  if (
    $(e.target).hasClass('accessibility-menu__close') ||
    !$('.accessibility-menu.opened').find(e.target).length
  ) {
    close_accessibility_menu(e, true);
  }
}

export function open_accessibility_menu(e) {
  e.preventDefault();

  var $current_accessibility_menu = $($(e.target).data('menu-target'));

  if (!$current_accessibility_menu.hasClass('opened')) {
    $current_accessibility_menu.addClass('opened');
    change_toggle_state($(e.target), 'opened');
    Focus.trap_focus_inside($current_accessibility_menu);

    // Donner un petit délais pour que le CSS change le visibility:hidden à visible.
    // Le délais va donner le temps au Screen Reader de ce rendre compte que l'élément existe.
    setTimeout(function () {
      /* jshint ignore:start */
      init_close_accessibility_events();
      /* jshint ignore:end */
      var $first = $current_accessibility_menu.find('input').first();
      if ($first.is('[type="radio"]')) {
        $('[name="' + $first.attr('name') + '"]')
          .filter(':checked')
          .focus();
      } else {
        $first.focus();
      }
    }, 100);
  }
}

export function toggle_accessibility_menu(e) {
  e.preventDefault();

  var $current_accessibility_menu = $($(e.target).data('menu-target'));
  if (!$current_accessibility_menu.hasClass('opened')) {
    open_accessibility_menu(e);
  } else {
    close_accessibility_menu(e);
  }
}

export function init_toggle_accessibility_menu() {
  $('.accessibility-menu__toggle').on('click', toggle_accessibility_menu);
}

export function init_close_accessibility_events() {
  $(document)
    .on('click', try_closing_accessibility_menu_on_click)
    .on('keydown', try_closing_accessibility_menu_on_keydown);
}

/////////////// ON READY /////////////////
$(function () {
  extend_vtx_accessibility();
  init_toggle_accessibility_menu();
}); // end ready
